import '../../../styles/PageContent.css'
import React from 'react';

const SkillsTab = () => {
    return (
        <div className="contents_threeFourthWidth" id="skillsTab">
            <br />

            <h2>Skills</h2>
            <br />

            <h3>Web Development</h3>
            <div id="split">
                <p id="splitBy3">HTML5</p>
                <p id="splitBy3">CSS</p>
                <p id="splitBy3">JavaScript</p>
            </div>
            <div id="split">
                <p id="splitBy2">Bootstrap</p>
                <p id="splitBy2">Asp.net</p>
            </div>
            <div id="split">
                <p id="splitBy2">Front End Developer</p>
                <p id="splitBy2">Single Page Applications</p>
            </div>
            <div id="split">
                <p id="splitBy3">Less</p>
                <p id="splitBy3">Sass/Scss </p>
                <p id="splitBy3">Wordpress</p>
            </div>
            <br />

            <h3>JavaScript</h3>
            <div id="split">
                <p id="splitBy3">JavaScript</p>
                <p id="splitBy3">TypeScript</p>
                <p id="splitBy3">JQuery</p>
            </div>
            <div id="split">
                <p id="splitBy2">Node.js</p>
                <p id="splitBy2">NPM</p>
            </div>
            <div id="split">
                <p id="splitBy3">React</p>
                <p id="splitBy3">Vue</p>
                <p id="splitBy3">Angular</p>
            </div>
            <br />

            <h3>SQL</h3>
            <div id="split">
                <p id="splitBy3">SQL</p>
                <p id="splitBy3">MySQL</p>
                <p id="splitBy3">NoSQL</p>
            </div>
            <br />

            <h3>GIT</h3>
            <div id="split">
                <p id="splitBy2">Git</p>
                <p id="splitBy2">Github</p>
            </div>
            <br />

            <h3>Coding</h3>
            <div id="split">
                <p id="splitBy3">C++</p>
                <p id="splitBy3">C#</p>
                <p id="splitBy3">Java</p>
            </div>
            <div id="split">
                <p id="splitBy2">Python</p>
                <p id="splitBy2">JSON</p>
            </div>
            <div id="split">
                <p id="splitBy2">JavaScript</p>
                <p id="splitBy2">TypeScript</p>
            </div>
            <div id="split">
                <p id="splitBy3">OpenGL</p>
                <p id="splitBy3">PHP</p>
                <p id="splitBy3">Laravel</p>
            </div>
            <br />

            <h3>Game Development</h3>
            <div id="split">
                <p id="splitBy3">Unity</p>
                <p id="splitBy3">UE5</p>
                <p id="splitBy3">Godot</p>
            </div>
            <div id="split">
                <p id="splitBy3">C# in Unity</p>
                <p id="splitBy3">C++ in UE5</p>
                <p id="splitBy3">Blender 3D</p>
            </div>
            <br />

            <h3>Software Developemnt</h3>
            <div id="split">
                <p id="splitBy3">RayLib5</p>
                <p id="splitBy3">LWJGL</p>
                <p id="splitBy3">GLFW</p>
            </div>
            <br />

            <h3>IDE</h3>
            <div id="split">
                <p id="splitBy2">VS Community</p>
                <p id="splitBy2">VS Code</p>
            </div>
            <div id="split">
                <p id="splitBy3">Jetbrains Rider</p>
                <p id="splitBy3">Jetbrains Intellij Idea </p>
                <p id="splitBy3">Eclipse</p>
            </div>
            <br />

            <h3>Operating Systems</h3>
            <div id="split">
                <p id="splitBy2">Linux</p>
                <p id="splitBy2">Windows</p>
            </div>
            <br />

            <h3>Adobe</h3>
            <div id="split">
                <p id="splitBy4">Photoshop</p>
                <p id="splitBy4">Illustrator</p>
                <p id="splitBy4">XD</p>
                <p id="splitBy4">After Effects</p>
            </div>
            <br />

            <h3>Affinty</h3>
            <div id="split">
                <p id="splitBy2">Photo</p>
                <p id="splitBy2">Designer</p>
            </div>
            <br />

            <h3>Music</h3>
            <div id="split">
                <p id="splitBy2">Ableton Live 10</p>
                <p id="splitBy2">Audacity</p>
            </div>
            <br />

            <h3>Microsoft</h3>
            <div id="split">
                <p id="splitBy3">Word</p>
                <p id="splitBy3">Powerpoint</p>
                <p id="splitBy3">Excel</p>
            </div>
            <br />
        </div>
    )
}

export default SkillsTab